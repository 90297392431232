@import "../CommonSCSS/mixins.scss";

.MuiModal-root {
  .MuiBackdrop-root {
    background: transparent;
  }
}

.modalWrapper {
  @include position(fixed, $top: 0px, $left: 0px);
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  z-index: 991;
  background: rgba(#000000, 0.2);
  backdrop-filter: blur(4px);

  .modalContent {
    background: $white;
    @include border-radius(20px);
    @include box-shadow(0px 6px 12px rgba($black, 0.2));
    font-family: "Montserrat", sans-serif;
    margin: 60px auto;
    // padding: 0px 12px;
    max-width: 800px;
    width: 100%;
    // transform: translateY(-50%);
    // position: relative;
    // top: 50%;
    position: relative;

    &.smWidth {
      max-width: 600px;
    }

    &.xsWidth {
      max-width: 420px !important;
      padding: 40px;
    }

    &.fullwidth {
      max-width: 100%;
      height: 100%;
      margin: 0;
    }


    &.kycPopups{
      padding:20px;
     .popup-heading {
        font-weight: 600;
        @include fontSize(20px);
        line-height: 24px;
        text-align: center;
        margin-bottom: 20px;
      }

      .grey_p {
          font-weight: 400;
          @include fontSize(15px);
          line-height: 18px;
          text-align: center;
          margin-bottom: 16px;
          color: #999;
      }

      .policy_number_box {
          background: #FFF9E6;
          color: #FFC929;
          border: 1px solid #FFC929;
          padding: 10px 16px;
          border-radius: 30px;
          @include fontSize(18px);
          display: inline-block;
      }

      h6 {
          font-weight: 600;
          @include fontSize(16px);
          line-height: 18px;
          margin-bottom: 16px;
          text-align: left;

          span {
              font-weight: 400;
              display: block;
              text-transform: uppercase;
              @include fontSize(15px);
              margin-top: 8px;
          }
      }
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
      font-family: "Montserrat", sans-serif;
    }

    .title {
      background: #F6F9FE;
      position: relative;
      margin-bottom: 24px;
      padding: 8px 12px;
      @include border-radius(20px 20px 0px 0px);

      h3 {
        @include fontSize(18px);
        font-weight: 400 !important;

        span {
          font-weight: 600;
        }
      }
    }

    .closePopup {
      @include border-radius(40px);
      position: absolute;
      min-width: 20px;
      padding: 0px;
      height: 30px;
      width: 30px;
      top: 16px;
      right: 16px;

      &:before {
        background: $black;
        content: "";
        @include position(absolute, $left: 14px, $top: 6px);
        transform: rotate(45deg);
        height: 16px;
        width: 2px;
        transition: transform 0.3s ease-in-out;
      }

      &:after {
        @extend :before;
        transform: rotate(-45deg);
      }

      &:hover {
        &:before {
          transform: rotate(90deg);
          background: $red;
        }

        &:after {
          transform: rotate(-90deg);
          background: $red;
        }
      }
    }
  }
}

.modalWrapperMobile {
  background: $white;
  @include position(fixed, $top: 0px, $left: 0px);
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  z-index: 991;

  &.mwMobile-kyc{
    overflow-y: auto;
    backdrop-filter: blur(4px);
    background: rgba(#000, 0.2);
    display: flex;
    align-items: center;
    .modalContent{
      padding: 20px 8px;
      margin: 20px 12px;
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
      border-radius: 20px;
      position: relative;
      .popup-heading {
          font-weight: 600;
          @include fontSize(20px);
          line-height: 24px;
          text-align: center;
          margin-bottom: 20px;
      }
    
      .grey_p {
        font-weight: 400;
        @include fontSize(15px);
        line-height: 18px;
        text-align: center;
        margin-bottom: 16px;
        color: #999;
      }
    
      .policy_number_box {
        background: #FFF9E6;
        color: #FFC929;
        border: 1px solid #FFC929;
        padding: 10px 16px;
        border-radius: 30px;
        @include fontSize(18px);
        display: inline-block;
      }
    
      h6 {
        font-weight: 600;
        @include fontSize(16px);
        line-height: 18px;
        margin-bottom: 16px;
        text-align: left;
    
        span {
          font-weight: 400;
          display: block;
          text-transform: uppercase;
          @include fontSize(15px);
          margin-top: 8px;
        }
      }
      hr {
        border-top: 1px solid rgba(0, 0, 0, 0.15);
        border-bottom: none;
        border-left: none;
        border-right: none;
        margin-bottom: 12px;
      }
            .close-button {
              background: transparent;
              color: #000;
              border-radius: 50px;
              @include position(absolute, $right: 16px, $top: 16px);
              $size: 30px;
              height: $size;
              width: $size;
              box-sizing: border-box;
              line-height: $size;
              z-index: 99;
      
              &:before,
              &:after {
                $width: 20px;
                $height: 1.5px;
                transform: rotate(-45deg);
                content: "";
                @include position(absolute, $right: 5px, $top: 14px);
                height: $height;
                width: $width;
                background-color: #000;
                transition: all 0.2s ease;
              }
      
              &:after {
                transform: rotate(-135deg);
              }
      
              &:hover {
                &:before {
                  background-color: #de4b4b;
                  transform: rotate(0deg);
                }
      
                &:after {
                  background-color: #de4b4b;
                  transform: rotate(-180deg);
                }
              }
            }
    }
  }

  .modalContent {
    background: $white;
    width: 100%;
    &:focus-visible{
      outline: none;
    }
  }
}

