@import "./mixins.scss";

.mainWrapper {
    padding-top: 140px;

    @media(max-width:899px) {
        padding-top: 96px;
    }

    h3 {
        color: $primary;

        @media(max-width:899px) {
            font-size: 18px;
            line-height: 26px;
        }
    }

    .contactHeading {
        color: $primary;
        position: relative;
        text-align: center;
        padding: 4px 0px;

        @media(max-width:899px) {
            font-size: 24px;
            line-height: 36px;
        }

        &:before {
            content: 'CONTACT';
            position: absolute;
            top: -4px;
            left: 0px;
            color: rgba($black, 0.08);
            @include fontSize(96px);
            font-family: 'Roboto', sans-serif;
            width: 100%;
            // height: 100px;
            text-align: center;

            @media(max-width:899px) {
                font-size: 40px;
                line-height: 54px;
            }
        }
    }

    .claimHeading {
        @extend .contactHeading;

        &:before {
            content: 'CLAIMS';
        }
    }

    .complaintHeading {
        @extend .contactHeading;

        &:before {
            content: 'COMPLAINT';
        }
    }

    .careerHeading {
        @extend .contactHeading;

        &:before {
            content: 'CAREER WITH US';
        }
    }

    .aboutHeading {
        @extend .contactHeading;

        &:before {
            content: 'GET TO KNOW US';
        }
    }

    .pageHeading {
        @extend .contactHeading;

        &:before {
            display: none;
        }
    }

    .openings {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 24px;
        margin-top: 20px;
        margin-bottom: 60px;

        li {
            width: 100%;
            max-width: 380px;
            border: 1px solid #53a4f2;
            @include border-radius(4px);
            text-align: center;

            p {
                font-family: "Roboto";
                @include fontSize(16px);
                margin: 8px 0px;

                span {
                    font-weight: 500;
                }
            }

            ul {
                margin-left: 12px;

                li {
                    list-style-type: disc;
                    text-align: left;
                    @include fontSize(14px);
                    border: none;
                    line-height: 20px;
                    margin-top: 4px;
                }
            }
        }
    }

    .para {
        font-family: "Roboto", sans-serif;
        color: rgba(0, 0, 0, 0.56);
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        text-align: justify;
        margin-bottom: 0px;
    }

    .aboutList {
        margin-left: 13px;
        margin-top: 20px;

        li {
            font-family: "Roboto";
            color: rgba(0, 0, 0, 0.56);
            font-weight: normal;
            font-size: 14px;
            line-height: 24px;
            margin-bottom: 10px;
            list-style-type: none;
            padding-left: 15px;
            position: relative;

            &:before {
                content: "";
                height: 11px;
                width: 18px;
                background: url("../../public/images/blue-tick.svg") no-repeat;
                position: absolute;
                top: 8px;
                left: -12px;
                padding-right: 7px;
            }
        }
    }

    .listView {
        margin-left: 16px;
        margin-top: 8px;
        margin-bottom: 12px;

        li {
            font-family: "Roboto";
            color: rgba(0, 0, 0, 0.56);
            font-weight: normal;
            font-size: 14px;
            line-height: 24px;
            margin-bottom: 0px;
            list-style-type: none;
            padding-left: 10px;
            position: relative;

            &:before {
                background: url("../../public/images/list-view.svg") no-repeat;
                content: " ";
                height: 12px;
                width: 12px;
                position: absolute;
                top: 6px;
                left: -15px;
            }
        }
    }

    .listType {
        margin-left: 20px;

        li {
            list-style-type: lower-roman;
            font-family: "Roboto";
            color: rgba(0, 0, 0, 0.56);
            font-weight: normal;
            font-size: 14px;
            line-height: 24px;
            text-align: justify;
        }
    }

    .alphaList {
        margin-left: 20px;

        li {
            list-style-type: lower-alpha;
            font-family: "Roboto";
            color: rgba(0, 0, 0, 0.56);
            font-weight: normal;
            font-size: 14px;
            line-height: 24px;
            text-align: justify;
        }
    }

    .numberList {
        margin-left: 20px;

        li {
            list-style-type: decimal;
            font-family: "Roboto";
            color: rgba(0, 0, 0, 0.56);
            font-weight: normal;
            font-size: 14px;
            line-height: 24px;
            margin-bottom: 0px;

            span {
                color: $primary;
                font-weight: 500;
            }
        }
    }

    .MuiAccordion-root {
        box-shadow: none;
        margin-bottom: 12px;

        &:before {
            display: none;
        }

        .MuiTypography-root {
            @include fontSize(18px);
            font-weight: 500;
            color: $primary;
        }

        .MuiAccordionSummary-root {
            min-height: 24px;
            padding: 0px;
        }

        .MuiAccordionSummary-content {
            &.Mui-expanded {
                margin: 0px;
            }
        }

        .MuiAccordionSummary-expandIconWrapper {
            transition: none;
        }

        .Mui-expanded {
            transform: rotate(0deg !important);
        }
    }

    .ct_list {
        display: flex;
        flex-wrap: wrap;
        gap: 24px;
        margin: 40px 0;

        p {
            line-height: 24px;
        }

        @media(max-width:699px) {
            gap: 12px;
            margin: 20px 0 40px 0;

            li {
                width: calc(50% - 6px);

                &:last-child {
                    width: 100%;
                }

                img {
                    height: 30px;
                }

                h5 {
                    font-size: 16px;
                }
            }
        }
    }
}