@import "./CommonSCSS/mixins.scss";
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import "./CommonSCSS/FontStyle.scss";

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  text-rendering: auto;
  text-rendering: optimizeSpeed;
  text-rendering: optimizeLegibility;
  text-rendering: geometricPrecision;
  font-synthesis: none;
  -moz-font-feature-settings: "kern";
  -moz-osx-font-smoothing: grayscale;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: #ffffff;
  font-family: "Roboto", sans-serif;
  height: 100%;
  margin: 0px;
  padding: 0px;
  @include fontSize(16px);
  overflow-x: hidden;
  font-smooth: always;
  font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-size-adjust: none;
  -webkit-text-size-adjust: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-family: "Montserrat", sans-serif;
}

p {
  @include fontSize(14px);
  font-family: "Roboto", sans-serif;
}


.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

ul {
  margin: 0;
  padding: 0;

  li {
    list-style: none;
  }
}


.MuiGrid2-container {
  // max-width: 1360px;
  margin-left: auto !important;
  margin-right: auto !important;
  width: 100% !important;
}

.row {
  max-width: 1224px;
  width: 100%;
  margin-left: auto !important;
  margin-right: auto !important;
}

img {
  vertical-align: middle;
}

.text-xs-center {
  @media(max-width:600px) {
    text-align: center;
  }
}

.MuiDialogContent-root {
  overflow: hidden;
}

.textGrey {
  color: rgba(0, 0, 0, 0.5);
}

.textBold {
  font-weight: 600;
}

.text-md-left {
  @media(max-width:899px) {
    text-align: left !important;
  }
}

.text-md-right {
  @media(max-width:899px) {
    text-align: right !important;
  }
}

.text-md-left {
  @media(max-width:899px) {
    text-align: left !important;
  }
}

.text-md-center {
  @media(max-width:899px) {
    text-align: center !important;
  }
}

a {
  cursor: pointer;
  text-decoration: none !important;
}