@import "./../../../CommonSCSS/mixins.scss";

.Footer {
  padding-top: 20px;
  background: $primary;

  .topbar-strip {
    display: flex;
    justify-content: space-between;
    align-items: center;

    li {
      display: inline-block;
    }

    .ahalia-box {
      padding: 5px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      gap: 10px;
      background: $white;
      width: 162px;

      img {
        width: 50px;
        height: 50px;
      }

      .powered_p {
        font-size: 10px;
        line-height: 16px;
        color: $secondary;
        font-family: 'Montserrat';
        text-align: left;

        span {
          font-size: 12px;
          font-weight: 700;
          display: block;
          color: $black;
          font-family: 'Roboto';
        }
      }
    }

    .social-icons {
      display: flex;
      gap: 10px;

      a {
        display: inline-block;

        svg {
          width: 25px;
          height: 25px;
          fill: $white;

          &:hover {
            fill: $secondary;
          }
        }

      }
    }

    .email_li {
      text-align: left;

      p {
        font-size: 14px;
        font-weight: 500;
        color: $white;

        a {
          color: $white;
          font-weight: 400;

          &:hover {
            color: $secondary;

          }
        }
      }
    }
  }

  .list1 {
    padding: 6px 0px;
    display: flex;
    gap: 20px;
    justify-content: center;

    li {
      a {
        font-size: 14px;
        line-height: 20px;
        color: $white;
        padding: 2px 0px;
        display: inline-block;
      }
    }
  }

  .list2 {
    @extend .list1;

    li {
      a {
        font-weight: 500;
      }
    }
  }

  hr {
    border: none;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
  }

  .disclaimer_p {
    @include fontSize(12px);
    font-weight: 300;
    color: $white;
    text-align: center;
  }

  .copyright_box {
    padding: 5px 0 ;
    background: #162FB1;
    p{
      font-size: 14px;
      line-height: 24px;
      color: $white;
    }
  }
}