@import "../../../CommonSCSS/mixins.scss";

.ctaBtn {
  %primary {
    background: $primary;
    color: $white;
    @include border-radius(100px);
    font-family: "Montserrat", sans-serif;
    text-transform: capitalize;
    font-weight: 700;
    @include box-shadow(0px 0px 0px rgba($white, 0));

    &:hover {
      background: lighten($primary, 8%);
      @include box-shadow(0px 0px 0px rgba($white, 0));
    }
  }
  %secondary {
    background: $secondary;
    color: $white;
    @include border-radius(100px);
    font-family: "Montserrat", sans-serif;
    text-transform: capitalize;
    font-weight: 700;
    @include box-shadow(0px 4px 12px rgba($black, 0.2));

    &:hover {
      background: lighten($secondary, 8%);
      @include box-shadow(0px 0px 0px rgba($white, 0));
    }
  }

  // Primary Buttons
  .regularPrimaryBtn {
    @extend %primary;
    @include fontSize(14px);
    line-height: 24px;
    padding: 12px 28px;
  }

  .mediumPrimaryBtn {
    @extend %primary;
    @include fontSize(14px);
    line-height: 24px;
    padding: 8px 24px;
  }

  .smallPrimaryBtn {
    @extend %primary;
    @include fontSize(12px);
    padding: 4px 8px;
  }
  .smallSecondaryBtn {
    @extend %primary;
    @include fontSize(10px);
    padding: 4px 12px;
  }

  .regularSecondaryBtn {
    @extend %secondary;
    @include fontSize(14px);
    line-height: 24px;
    padding: 12px 28px;
  }
  .mediumSecondaryBtn {
    @extend %secondary;
    @include fontSize(14px);
    line-height: 24px;
    padding: 8px 24px;
  }

}