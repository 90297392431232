@import "./../../../CommonSCSS/mixins.scss";

.Navbar {
  @include position(fixed, $top: 0px, $left: 0px);
  z-index: 99;
  width: 100%;
  background: $white;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15);

  .top-strip {
    background: $primary;
    width: 100%;

    p {
      @include fontSize(16px);
      text-align: center;
      color: $white;
      letter-spacing: 2px;
      line-height: 18px;
      padding: 11px 12px;
    }
  }

  .logo-strip {
    padding: 10px 0px;

    .logo {
      height: 50px;
    }

    .nav-section {
      ul {
        display: flex;
        align-items: center;
        justify-content: end;
        gap: 30px;

        li {
          .nav-link {
            text-decoration: none;
            @include fontSize(16px);
            line-height: 24px;
            color: $black;
            cursor: pointer;
            padding-top: 4px;
            padding-bottom: 4px;
            display: inline-block;
            font-family: "Montserrat";

            &:hover {
              color: $secondary;
            }

            &.product-link {
              text-transform: capitalize;
              font-family: "Montserrat";
              color: $black;
              padding: 4px 16px 4px 0px;
              min-height: 10px;
              font-weight: 400;
              position: relative;
              font-size: 16px;

              &:before {
                @include position(absolute, $top: 14px, $right: 0);
                content: "";
                background: url("./../../../../public/images/bottom-dropdown-icon.svg") no-repeat 0 0/10px;
                height: 7px;
                width: 10px;
              }

              &:hover {
                color: $secondary;
                background: none;
                transition: none;
              }

              &:focus,
              &:active {
                background: none;
                transition: none;
              }
            }
          }
        }
      }
    }

    .rounded {
      @include border-radius(100px !important);
      box-shadow: 0px 6px 7px 0px rgba(0, 181, 135, 0.2) !important;
    }
  }
}

.productMenu {
  .MuiMenu-paper .MuiMenu-list .MuiMenuItem-root {
    font-size: 1rem;
    border-bottom: 1px dashed $primary;

    &:hover {
      background: $primary;
      color: $white;
    }
  }

  .MuiMenu-paper {
    max-height: 240px !important;
  }
}