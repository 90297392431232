@import "./mixins.scss";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300..700&display=swap");

h1 {
  @include fontSize(49px);
  line-height: 59px;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
}

h2 {
  @include fontSize(36px);
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  line-height: 54px;
}

h3 {
  @include fontSize(28px);
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  line-height: 42px;
}

h4 {
  @include fontSize(24px);
  font-weight: 700;
  line-height: 36px;
  font-family: "Montserrat", sans-serif;
}

h5 {
  @include fontSize(18px);
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  line-height: 30px;
}

h6 {
  @include fontSize(16px);
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  line-height: 24px;
}
