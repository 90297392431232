@import "./../../../CommonSCSS/mixins.scss";

.homepageWrapper_m {
  padding-top: 74px;

  h4 {
    // font-size: 18px;
    line-height: 34px;

    span {
      color: $secondary;
    }
  }

  .product-header-mobile {
    position: relative;
    padding-top: 15px;
    margin-bottom: 40px;

    h5 {
      color: $primary;
      font-family: "Roboto";
      font-weight: 500;
      margin-bottom: 4px;
    }

    h3 {
      color: $primary;

      span {
        color: $secondary;
      }
    }

    .products-box {
      position: relative;

      .productList {
        display: flex;
        flex-wrap: wrap;
        gap: 24px;

        li {
          width: calc((100% / 2) - 12px);
          text-align: center;
          box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
          border-radius: 10px;
          background: $white;
          position: relative;

          &.disabled {
            cursor: wait;

            img,
            h6 {
              opacity: 0.4;
            }

            &:before {
              background: url("../../../../public/images/coming-soon.svg") no-repeat center/90px;
              content: '';
              position: absolute;
              top: 0px;
              left: 0px;
              width: 100%;
              height: 100%;
              z-index: 9;
            }
          }

          a {
            padding: 20px 12px;
            display: inline-block;
            min-height: 147px;

            &.healthproduct {
              h6 {
                color: $primary;
              }
            }

            &.termproduct {
              h6 {
                color: $secondary;
              }
            }

            &.investmentproduct {
              h6 {
                color: #faac1d;
              }
            }

            &.carproduct {
              h6 {
                color: #4724cd;
              }
            }

            &.bikeproduct {
              h6 {
                color: #fb39ff;
              }
            }

            &.travelproduct {
              h6 {
                color: #68ad19;
              }
            }

            img {
              height: 50px;
            }

            h6 {
              font-weight: 500;
              margin-top: 15px;
              font-family: "Roboto", sans-serif;
              font-size: 14px;
            }

            p {
              font-size: 12px;
              color: $grey;
            }
          }

          span {
            position: absolute;
            padding: 5px 10px;
            font-size: 11px;
            line-height: 18px;
            color: #6a43ff;
            background: #dad0ff;
            display: inline-block;
            min-width: 108px;
            font-family: "Roboto";
            left: calc(50% - 54px);
            border-radius: 36px;
            bottom: -14px;
          }
        }
      }

      .header-illustration {
        max-width: 414px;
        width: 100%;
      }

      .irda-box {
        border: 1px solid rgba(0, 0, 0, 0.56);
        padding: 5px 20px;
        display: inline-flex;
        gap: 15px;
        align-items: center;
        border-radius: 60px;
        background: $white;

        .licence {
          font-size: 12px;
          font-weight: 400;
          color: #232222;
          margin-bottom: 0px;
          line-height: 18px;
          text-align: left;

          span {
            display: block;
            font-weight: 500;
          }
        }
      }
    }
  }

  .work-section-mobile {
    margin-bottom: 40px;

    .work-img {
      max-width: 246px;
      width: 100%;
    }

    .workPolicy-list {
      width: 100%;

      li {
        display: flex;
        align-items: center;
        gap: 16px;
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }

        span {
          min-width: 60px;
          height: 75px;
          color: $white;
          display: inline-flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          &.step1 {
            background-color: #367cc0;
          }

          &.step2 {
            background-color: #ff5100;
          }

          &.step3 {
            background-color: #faac1d;
          }

          strong {
            font-size: 32px;
            line-height: 36px;
            font-weight: 500;
          }
        }

        h5 {
          color: rgba(35, 34, 34, 1);
          margin-bottom: 5px;
          line-height: 20px;
          font-weight: 500;
          font-family: "Roboto";
        }

        p {
          color: rgba(0, 0, 0, 0.56);
          line-height: 24px;
        }
      }
    }
  }

  .claim_assistance_sec_mobile {
    margin-bottom: 40px;

    .claimBox {
      border: 1px solid $secondary;
      border-radius: 10px;
      overflow: hidden;

      .top_box {
        padding: 20px;

        p {
          text-align: justify;
          color: rgba(0, 0, 0, 0.56);
          line-height: 24px;
        }
      }

      .grey_box {
        background: #e0e0e0;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 25px;

        img {
          width: 100%;
          max-width: 334px;
        }
      }
    }
  }

  .products_sec_mobile {
    margin-bottom: 40px;

    .product_list {
      display: flex;
      gap: 24px;
      flex-wrap: wrap;
      justify-content: center;

      li {
        width: calc((100% / 3) - 16px);
        box-shadow: 0px -4px 15px rgba(0, 0, 0, 0.25);
        overflow: hidden;
        border-radius: 10px;
        background: $white;
        min-width: 108px;

        a {
          display: block;
          text-align: center;
          position: relative;
          height: 122px;

          &.disabled {
            cursor: wait;

            img,
            h6 {
              opacity: 0.4;
            }

            &:before {
              background: url("../../../../public/images/coming-soon.svg") no-repeat center/90px;
              content: '';
              position: absolute;
              top: 0px;
              left: 0px;
              width: 100%;
              height: 100%;
              z-index: 9;
            }
          }

          img {
            margin-top: 11px;
            width: 57px;
            height: 50px;
          }

          span {
            height: 43px;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            color: $white;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            border-radius: 35px 35px 9px 9px;

            &.car {
              background: #ff9898;
            }

            &.bike {
              background: #af9aff;
            }

            &.health {
              background: #ffcc6f;
            }

            &.term {
              background: #81bbff;
            }

            &.investment {
              background: #fd97ff;
            }

            &.travel {
              background: #8ee5b3;
            }
          }
        }
      }
    }
  }

  .testimonials-sec-mobile {
    margin-bottom: 40px;
    background: url("./../../../../public/images/testibg.svg") no-repeat center center/100%;

    .testimonial_slider {
      min-height: 200px;
      position: relative;
      padding-top: 90px;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        background: url("./../../../../public/images/carbon_quotes.svg") no-repeat 0 0/86px;
        width: 86px;
        height: 69px;
      }

      h5 {
        line-height: 18px;
        margin-bottom: 8px;
        color: rgba(0, 0, 0, 0.56);
      }

      p {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.56);
      }
    }

    .slick-dots {
      position: relative;
      text-align: left;

      li {
        width: 8px;
        height: 8px;
        margin: 0px 4px;

        &.slick-active {
          button {
            background: $secondary;
          }
        }

        button {
          width: 8px;
          height: 8px;
          background: $white;
          border-radius: 50%;

          &::before {
            display: none;
          }
        }
      }
    }
  }

  .partners-sec-mobile {
    margin-bottom: 40px;

    .partners-box {
      padding: 20px 15px;
      border: 1px solid rgba(189, 189, 189, 1);
      border-radius: 10px;
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);

      .partners-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 15px;

        li {
          width: calc((100% / 3) - 10px);
          text-align: center;

          .partner_img {
            width: 100%;
          }
        }
      }
    }
  }
}